import React from "react"
import Button from "../components/button"
import Background from "../images/background.png"
import Phone from "../images/phone.png"
import PhoneNoShadow from "../images/phone-no-shadow.png"
import AppLayout from "../layouts/app"
import compareVersions from "compare-versions"

export default function Home() {
    const versions = require('../../docs/versions.json').sort((a, b) => {
        return compareVersions(a.name, b.name)
    }).reverse()
    
    const latestVersion = versions[0]

    return (
        <AppLayout title="Home">
            <div className="relative overflow-hidden w-full" style={{ minHeight: `calc(100vh - 6rem)` }}>
                <img src={Background} alt="Vector waves" className="absolute max-w-none w-full h-full -z-1"/>

                <div className="container relative flex flex-col lg:flex-row pt-16 flex justify-center items-center lg:items-start">
                    <div className="max-w-xs lg:max-w-sm">
                        <img className="block lg:hidden w-36" src={PhoneNoShadow} alt="ImagineAR app on phone"/>
                        <img className="hidden lg:block transform -rotate-12" src={Phone} alt="ImagineAR app on phone"/>
                    </div>

                    <div className="text-center mt-12 lg:mt-24 max-w-xxl mb-12">
                        <h2 className="text-app-400 font-bold text-4xl lg:text-6xl mb-6">
                            ImagineAR
                        </h2>

                        <p className="text-2xl lg:text-3xl text-app-700 leading-tight lg:px-8 mb-10">
                            The industry's most versatile AR platform.
                            </p>

                        <div className="flex items-center justify-center space-x-4">
                            <Button to={latestVersion.path} size="lg">
                                View the Docs
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}